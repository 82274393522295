import React, { useEffect } from "react";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";

import { AMOUNT_OPTIONS } from "../../../settings";
import { updateValue } from "../../../store/actions/form";

import triangle from "../../../images/triangle.svg";

const SingleValue = ({ children, ...props }) => {
  let prefix = <span>$</span>;
  let postfix = <span>MXN</span>;

  if (children === "Otros Montos") {
    prefix = "";
    postfix = "";
  }

  return (
    <components.SingleValue {...props}>
      {prefix} {children} {postfix}
    </components.SingleValue>
  );
};

const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props}>
    <img src={triangle} alt="" />
  </components.DropdownIndicator>
);

const IndicatorSeparator = () => {
  return null;
};

const Option = ({ children, ...props }) => {
  let prefix = <span>$</span>;
  let postfix = <span>MXN</span>;

  if (children === "Otros Montos") {
    prefix = "";
    postfix = "";
  }

  return (
    <components.Option {...props}>
      {prefix} {children} {postfix}
    </components.Option>
  );
};

const customStyles = {
  control: provided => ({
    ...provided,
    borderRadius: 15,
    border: "1px solid #ff9d00",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#ff9d00",
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: "12px 8px",
  }),
  singleValue: provided => ({
    ...provided,
    width: "100%",
    textAlign: "center",
    fontSize: 26,
    fontWeight: 700,
    color: "#ff9d00",
    "& span": {
      fontWeight: 400,
    },
  }),
  indicatorsContainer: provided => ({
    ...provided,
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: "8px 26px 8px 8px",
  }),
  menu: provided => ({
    ...provided,
    overflow: "hidden",
    borderRadius: 15,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    background: isSelected ? "#ff9d00" : isFocused ? "#ffebcc" : "#ffffff",
    textAlign: "center",
    fontSize: 26,
    fontWeight: 700,
    color: isSelected ? "#ffffff" : isFocused ? "#ff9d00" : "#ff9d00",
    "& span": {
      fontWeight: 400,
    },
  }),
};

export const SelectAmount = () => {
  const dispatch = useDispatch();
  const amountData = useSelector(state => state.form);
  const amountValue = amountData.amountDefault;
  const currentItem = AMOUNT_OPTIONS.filter(
    item => item.value === amountValue
  )[0];

  useEffect(() => {
    updateValue(dispatch, { amount: amountData.amountDefault });
  }, []);

  const handleChange = selectedOption => {
    updateValue(dispatch, { amount: selectedOption.value });
  };

  return currentItem ? (
    <>
      <label htmlFor="amount">¿Cuánto necesitas?</label>
      <Select
        className="amount"
        onChange={handleChange}
        options={AMOUNT_OPTIONS}
        defaultValue={currentItem}
        components={{
          SingleValue,
          DropdownIndicator,
          IndicatorSeparator,
          Option,
        }}
        styles={customStyles}
        isSearchable={false}
      />
    </>
  ) : null;
};
