import React from "react";

import { Main } from "../components/MainBlock";
import { HowTo } from "../components/HowToBlock";
import { Reviews } from "../components/ReviewsBlock";
import { Faq } from "../components/FaqBlock";
import { Info } from "../components/InfoBlock";
import { Content } from "../components/ContentBlock";
import { Creditors } from "../components/CreditorsBlock";

export const renderBlock = (block, pageId, comparadorName, comparadorLead) => {
  switch (block.fieldGroupName) {
    case "Page_Components_Component_Main":
      return <Main data={block} id={pageId} />;
    case "Page_Components_Component_HowTo":
      return <HowTo data={block} id={pageId} />;
    case "Page_Components_Component_Reviews":
      return <Reviews data={block} id={pageId} />;
    case "Page_Components_Component_Faq":
      return <Faq data={block} id={pageId} />;
    case "Page_Components_Component_Info":
      return <Info data={block} id={pageId} />;
    case "Page_Components_Component_Content":
      return <Content data={block} id={pageId} />;
    case "Page_Components_Component_Creditors":
      return (
        <Creditors
          data={block}
          id={pageId}
          comparadorName={comparadorName}
          comparadorLead={comparadorLead}
        />
      );
    default:
      return null;
  }
};
