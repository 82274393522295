import React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import styled from "styled-components";

export const Content = ({ data }) => {
  const { text } = data;

  return (
    <StyledContentBlock className="content">
      <Container>
        <div
          className="content_content-wrapper"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Container>
    </StyledContentBlock>
  );
};

const StyledContentBlock = styled.div`
  padding: 10px 0 50px;

  @media (min-width: 768px) {
    padding: 80px 0 100px;
  }

  h1 {
    margin-bottom: 15px;
    color: #378aff;
    font-size: 16px;
    font-weight: 700;

    @media (min-width: 768px) {
      margin-bottom: 50px;
      font-size: 28px;
    }
  }

  h2 {
    color: #4c4c4c;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  p,
  h3 {
    font-size: 14px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 18px;
      margin-bottom: 50px;
    }
  }

  h3 {
    margin-bottom: 0;
    font-weight: 400;
  }

  p:last-child {
    margin-bottom: 0;
  }

  p + ul,
  p + ol {
    margin-top: -15px;

    @media (min-width: 768px) {
      margin-top: -40px;
    }
  }

  ul,
  ol {
    padding: 0;
    margin-bottom: 20px;
    list-style-position: inside;

    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
  }
`;

export const query = graphql`
  fragment ContentBlock on WpPage_Components_Component_Content {
    fieldGroupName
    text
  }
`;
