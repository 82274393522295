import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import stars from "../../../images/stars.svg";

export const ReviewsSlider = ({ items }) => {
  const [isReady, setIsReady] = useState(false);
  const settings = {
    arrows: false,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 0,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: 0,
        },
      },
    ],
  };

  const sliderState = items.length > 3 ? "active-slider" : "inactive-slider";

  useEffect(() => {
    setIsReady(true);
  }, [isReady]);

  return isReady ? (
    <StyledReviewsSlider className={sliderState}>
      <Container>
        <Slider {...settings}>
          {items &&
            items.map((item, key) => {
              const image = getImage(item.photo.localFile);

              return (
                <div className="slide" key={key}>
                  <div className="slide__heading">
                    <div className="slide__photo">
                      <GatsbyImage image={image} alt={item.name} />
                    </div>
                    <div className="slide__name">
                      <h3>{item.name}</h3>
                      <div
                        className="rating"
                        style={{
                          backgroundSize: (100 / 5) * item.rating + "% 100%",
                        }}
                        aria-label={"Rating: " + item.rating}
                      />
                    </div>
                  </div>
                  <div className="slider__review">{item.review}</div>
                </div>
              );
            })}
        </Slider>
      </Container>
    </StyledReviewsSlider>
  ) : null;
};

const StyledReviewsSlider = styled.div`
  padding: 0 0 30px;
  @media (min-width: 768px) {
    padding: 30px 0;
  }
  .container {
    max-width: 1200px;
  }
  .slide {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.25);
  }
  .slide__heading {
    display: flex;
    align-items: center;
  }
  .slide__photo .gatsby-image-wrapper {
    overflow: hidden;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    box-shadow: 0 0 0 6px #cccccc;
    vertical-align: middle;
    @media (min-width: 992px) {
      width: 50px !important;
      height: 50px !important;
    }
  }
  .slide__name {
    margin: 0 0 0 10px;
    @media (min-width: 992px) {
      margin: 0 0 0 15px;
    }
    h3 {
      margin: 0;
      color: #4c4c4c;
      font-size: 16px;
      font-weight: 700;
      @media (min-width: 992px) {
        font-size: 20px;
      }
    }
    .rating {
      position: relative;
      width: 101px;
      height: 14px;
      background-image: linear-gradient(to right, #ffa600 0%, #ffa600 100%);
      background-position: left;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-clip: content-box;
      padding: 1px;
      margin: 5px 0 0;
      @media (min-width: 992px) {
        width: 86px;
        height: 12px;
      }
    }
    .rating::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(${stars});
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .slider__review {
    margin: 20px 0 0;
    color: hsla(0, 0%, 30%, 0.8);
    font-size: 14px;
    @media (min-width: 992px) {
      margin: 25px 0 0;
      font-size: 16px;
    }
  }

  // Slick
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: auto;
    padding: 10px;
    @media (min-width: 768px) {
      padding: 20px;
    }
    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }

  // Slider states
  &.active-slider {
    overflow: hidden;
    .slick-list {
      overflow: visible;
    }
    .slick-slide {
      transform: scale(0.9) translateX(10px);
      transform-origin: right;
      transition: transform 0.15s ease;
      @media (min-width: 768px) {
        transform: scale(0.85) translateX(16%);
      }
      @media (min-width: 1280px) {
        transform: scale(0.85) translateX(12%);
      }
      &.slick-active {
        transform: scale(0.9);
      }
      &.slick-current {
        transform: scale(1);
        transform-origin: center;
      }
      &.slick-current ~ .slick-slide {
        transform-origin: left;
      }
      &.slick-active ~ .slick-slide {
        &:not(.slick-active) {
          transform: scale(0.9) translateX(-10px);
          @media (min-width: 768px) {
            transform: scale(0.85) translateX(-16%);
          }
          @media (min-width: 1280px) {
            transform: scale(0.85) translateX(-12%);
          }
        }
      }
    }
  }
  &.inactive-slider {
    @media (max-width: 767px) {
      overflow: hidden;
      .slick-list {
        overflow: visible;
      }
      .slick-slide {
        transform: scale(0.9) translateX(10px);
        transform-origin: right;
        transition: transform 0.15s ease;
        &.slick-current {
          transform: scale(1);
          transform-origin: center;
        }
        &.slick-current ~ .slick-slide {
          transform: scale(0.9) translateX(-10px);
          transform-origin: left;
        }
      }
    }

    @media (min-width: 768px) {
      .slick-slide:first-child {
        transform: scale(0.9);
        transform-origin: right;
      }
      .slick-slide:last-child {
        transform: scale(0.9);
        transform-origin: left;
      }
    }
  }
`;
