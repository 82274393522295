import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import styled from "styled-components";

import { setCookie, getCookie } from "../../helpers/setCookies";

const BannerText = () => {
  return (
    <>
      Utilizamos cookies propias y de terceros para mejorar nuestros servicios y
      recopilar datos estadísticos. Si continúa navegando, consideramos que
      acepta su uso. Puede{" "}
      <a
        href="https://support.google.com/chrome/answer/95647?hl=es"
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        cambiar la configuración
      </a>{" "}
      o informarse acerca de nuestra{" "}
      <Link to="/politica-privacidad">Política de cookies</Link>.
    </>
  );
};

export const CookieBanner = () => {
  const acceptCookiePolicy = setCookieBlock => {
    setCookie("cookiesPolicy", "accepted");

    setCookieBlock(false);
  };

  const [cookieBlock, setCookieBlock] = useState(false);

  useEffect(() => {
    setCookieBlock(!getCookie("cookiesPolicy"));
  }, []);

  return (
    <StyledCookieBanner show={cookieBlock}>
      <Container>
        <Row className="align-items-center">
          <Col xs="12" md className="text-wrapper">
            <BannerText />
          </Col>
          <Col xs="12" md="auto" className="button-wrapper">
            <StyledButton
              type="button"
              className="btn btn-primary"
              onClick={() => acceptCookiePolicy(setCookieBlock)}
            >
              Aceptar
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </StyledCookieBanner>
  );
};

const StyledButton = styled.button`
  width: 100%;
  background: hsl(215, 100%, 61%);
  padding: 14px 28px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.1);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  @media (min-width: 768px) {
    background: hsl(37, 100%, 50%);
    min-width: 216px;
    text-transform: uppercase;
  }
  &:hover,
  &:focus {
    background: hsl(215, 100%, 58%);
    @media (min-width: 768px) {
      background: hsl(37, 100%, 47%);
    }
  }
  &:focus {
    box-shadow: none !important;
  }
`;

const StyledCookieBanner = styled.div`
  display: ${props => (props.show ? "block" : "none")};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f2f6fc;
  padding: 20px 0;
  font-size: 12px;
  color: hsla(0, 0%, 30%, 0.9);
  @media (min-width: 768px) {
    padding: 30px 0;
    font-size: 14px;
  }
  .container {
    transform: none !important; // fix wrong transform on comparador page
  }
  .button-wrapper {
    margin: 20px 0 0;
    @media (min-width: 768px) {
      margin: 0 0 0 20px;
    }
    @media (min-width: 992px) {
      margin: 0 0 0 100px;
    }
  }
  a {
    color: currentColor;
    font-weight: 700;
  }
`;
