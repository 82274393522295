import React from "react";
import { graphql } from "gatsby";

const ComparadorCardLogo = ({ logo, logoAlt }) => (
  <div className="col-6 col-md-2 col-sm-6 containerRecibir imgPartner">
    <img src={logo.localFile.publicURL} className="img-fluid" alt={logoAlt} />
  </div>
);

const ComparadorCardTerm = ({ termCount, termType }) => (
  <div className="col-6 col-md-2 col-sm-6 containerRecibir ContainerPoints">
    <div className="containerRating">
      <div className="containerPorcentaje">
        <span className="numero">{termCount}</span>
        <span className="porcentaje">{termType}</span>
      </div>
      <div className="textRating">Plazo de devolución</div>
    </div>
  </div>
);

const ComparadorCardMax = ({ maxAmount }) => (
  <div className="col-6 col-md-3 col-sm-6 containerRecibir aprobacion">
    <div>
      <span className="numero">{maxAmount}</span>
      <span className="porcentaje"></span>
    </div>
    <div className="aprobacion">Monto máximo</div>
  </div>
);

const ComparadorCardFirstLoan = ({ firstLoan }) => (
  <div className="col-6 col-md-2 col-sm-6 containerRecibir textoAyuda">
    Primer préstamo hasta {firstLoan}
  </div>
);

const ComparadorCardButton = ({ link, eventId, comparadorLead }) => {
  const modifiedLink = link.queryParam
    ? link.link + "?" + link.queryParam + "=" + comparadorLead
    : link.link;

  const gaEvent = (category, lead) => {
    typeof window !== "undefined" &&
      window.gtag &&
      window.gtag("event", "comparador", {
        event_category: category,
        event_label: lead,
      });
  };

  return (
    <div className="col-12 col-md-3 col-sm-12 containerRecibir">
      <a href={modifiedLink} onClick={gaEvent(eventId, comparadorLead)}>
        <div className="buttonRecibir">Recibe el dinero</div>
      </a>
    </div>
  );
};

export const Creditors = ({ data, comparadorLead }) => {
  return (
    data.list &&
    data.list.map((item, i) => {
      return (
        <div key={`${item.eventid}_${i}`} className="containerPartner">
          <ComparadorCardLogo logo={item.logo} logoAlt={item.logo.alt_text} />
          <ComparadorCardTerm
            termCount={item.termCount}
            termType={item.termType}
          />
          <ComparadorCardMax maxAmount={item.montoMaximo} />
          <ComparadorCardFirstLoan firstLoan={item.primerPrestamo} />
          <ComparadorCardButton
            link={item.link}
            eventId={item.eventid}
            comparadorLead={comparadorLead}
          />
        </div>
      );
    })
  );
};

export const query = graphql`
  fragment CreditorsBlock on WpPage_Components_Component_Creditors {
    fieldGroupName
    list {
      eventid
      link {
        link
        queryParam
      }
      montoMaximo
      primerPrestamo
      termType
      termCount
      logo {
        altText
        localFile {
          publicURL
        }
      }
    }
  }
`;
