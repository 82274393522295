export const SETTINGS = {
  URL_MASTER: "https://qa-delivery-goprestamo-mx-master.moneyman.ru/wp",
};

export const API = {
  // SEND_FORM: "/rest/sendForm.php",
  SEND_CALC: "/consultas/sendData.php",
  HEADERS: {
    POST: {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  },
};

export const FORM = {
  FORM_INCORRECT: "Проверьте правильность заполненных данных.",
  FORM_SUCCESS: "Спасибо! Мы свяжемся с Вами в ближайшее время.",
  FORM_ERROR: "Возникла ошибка, попробуйте позже.",
};

export const AMOUNT_OPTIONS = [
  { value: 1000, label: "1,000" },
  { value: 2000, label: "2,000" },
  { value: 3000, label: "3,000" },
  { value: 4000, label: "4,000" },
  { value: 5000, label: "5,000" },
];
