import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { navigate, graphql } from "gatsby";
import styled from "styled-components";
import { MainBackgroundImage } from "../BackgroundImage";
import { SelectAmount } from "./SelectAmount";

import heroSmall from "../../images/hero-small.png";

export const Main = ({ data, id }) => {
  const { title, description } = data;

  const redirectToRegistration = event => {
    event.preventDefault();
    navigate("/registration/");
  };

  return (
    <MainBackgroundImage heroImage={data.background.localFile}>
      <StyledMainBlock>
        <Container>
          <Row className="justify-content-between">
            <Col lg={id === 2 ? "4" : "5"} className="title">
              <div className="title__wrapper">
                <img src={heroSmall} className="main-hero-small" alt="" />
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </Col>
            <Col lg="4" className="form">
              <form onSubmit={event => redirectToRegistration(event)}>
                <div className="form__group">
                  <SelectAmount />
                </div>
                <div className="form__info">
                  Solicita en <span>5 minutos</span>
                </div>
                <button className="form__submit btn btn-primary">
                  Solicitar préstamo&nbsp;&gt;
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </StyledMainBlock>
    </MainBackgroundImage>
  );
};

const StyledMainBlock = styled.div`
  padding-bottom: 60px;
  @media (min-width: 585px) {
    padding-bottom: 100px;
  }
  @media (min-width: 992px) {
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .container {
    @media (max-width: 991px) {
      max-width: none;
    }
    @media (min-width: 992px) {
      padding-top: 75px;
    }
  }

  .title {
    background: #f2f6fc;
    @media (min-width: 992px) {
      background: transparent;
    }
    @media (min-width: 1280px) {
      margin-left: -60px;
    }
    &__wrapper {
      @media (max-width: 991px) {
        position: relative;
        max-width: 690px;
        padding: 30px 0;
        margin: 0 auto;
      }
      @media (min-width: 992px) {
        padding: 22px 0 0;
      }
    }
    h2 {
      color: #378aff;
      font-size: 6vw;
      font-weight: 700;
      line-height: 1.4;
      margin: 0 0 0 auto;
      width: 55%;
      @media (min-width: 585px) {
        font-size: 35px;
      }
      @media (min-width: 992px) {
        color: #fff;
        margin: 0;
        width: auto;
      }
    }
  }
  .main-hero-small {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 90%;
    @media (min-width: 992px) {
      display: none;
    }
  }
  .description {
    display: none;
    margin: 10px 0 0;
    color: #fff;
    font-size: 18px;
    @media (min-width: 992px) {
      display: block;
    }
    * {
      margin: 0;
    }
    * + * {
      margin: 10px 0 0;
    }
    table {
      td + td {
        padding: 0 0 0 10px;
      }
    }
  }

  .form {
    @media (max-width: 991px) {
      max-width: 720px;
      margin: 0 auto;
      padding-top: 20px;
    }
  }

  form {
    .form-group {
      margin-bottom: 10px;
    }
    label {
      width: 100%;
      margin: 0 0 10px;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
    }
    .form__info {
      margin: 20px 0 0;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      span {
        color: #ff9d00;
      }
    }
    .form__submit {
      width: 100%;
      display: block;
      background: #ff9d00;
      padding: 14px 28px;
      border: none;
      border-radius: 15px;
      margin: 20px 0 0;
      box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.1);
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .btn-primary:hover,
    .btn-primary:focus {
      background-color: #e68d00;
    }
    .btn-primary:not(:disabled):not(.disabled):active {
      background-color: #ff9d00;
    }
    .btn-primary:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const query = graphql`
  fragment MainBlock on WpPage_Components_Component_Main {
    fieldGroupName
    title
    description
    background {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 2000)
        }
      }
    }
  }
`;
