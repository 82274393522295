import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import graduation from "../../images/howto-graduation.svg";
import saveMoney from "../../images/howto-save-money.svg";
import contract from "../../images/howto-contract.svg";

export const HowTo = ({ data }) => {
  const images = [graduation, saveMoney, contract];
  const { title, list } = data;

  return (
    <StyledHowToBlock className="howto">
      <Container>
        <h2
          className="howto__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {list &&
          list.map((item, key) => (
            <Row className="howto__item no-gutters" key={key}>
              <Col xs="auto" className="howto__item-image">
                <img src={images[key]} alt="" />
              </Col>
              <Col className="howto__item-content">
                <h3
                  className="howto__item-title"
                  dangerouslySetInnerHTML={{ __html: item.listItemTitle }}
                />
                <div
                  className="howto__item-description"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </Col>
            </Row>
          ))}
      </Container>
    </StyledHowToBlock>
  );
};

const StyledHowToBlock = styled.div`
  counter-reset: item;
  padding: 20px 0;
  @media (min-width: 768px) {
    padding: 70px 0;
  }
  .howto__title {
    position: relative;
    margin: 0;
    color: #378aff;
    font-size: 20px;
    font-weight: 700;
    opacity: 0.8;
    @media (min-width: 768px) {
      margin: 0;
      font-size: 28px;
    }
  }
  .howto__item {
    counter-increment: item;
    display: block;
    text-align: center;
    max-width: 310px;
    margin: 50px auto 0;
    @media (min-width: 490px) {
      display: flex;
      text-align: left;
      margin: 50px 0 0;
      max-width: none;
    }
    &:first-of-type {
      margin: 20px auto 0;
      @media (min-width: 490px) {
        margin: 20px 0 0;
      }
      @media (min-width: 768px) {
        margin: 50px 0 0;
      }
    }
  }
  .howto__item-image {
    @media (min-width: 490px) {
      padding: 0 40px 0 10px;
    }
    @media (min-width: 768px) {
      padding: 0 70px 0 40px;
    }
    img {
      width: 90px;
      @media (min-width: 490px) {
        width: 110px;
      }
    }
  }
  .howto__item-content {
    margin: 20px 0 0;

    @media (min-width: 490px) {
      margin: 0;
    }
  }
  .howto__item-title {
    margin: 0;
    color: hsla(0, 0%, 30%, 0.8);
    font-size: 16px;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 19px;
    }
    &::before {
      content: counter(item) ". ";
    }
  }
  .howto__item-description {
    margin: 10px 0 0;
    color: hsla(0, 0%, 30%, 0.8);
    font-size: 14px;
    @media (min-width: 768px) {
      font-size: 17px;
    }
    ul {
      list-style: none;
    }
    li::before {
      content: "· ";
      font-weight: 700;
    }
    * {
      margin: 0;
      padding: 0;
    }
    * + * {
      margin: 10px 0 0;
    }
  }
`;

export const query = graphql`
  fragment HowToBlock on WpPage_Components_Component_HowTo {
    fieldGroupName
    title
    list {
      description
      listItemTitle
    }
  }
`;
