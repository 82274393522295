import React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import styled from "styled-components";

import { ReviewsSlider } from "./Slider";

export const Reviews = ({ data }) => {
  const { title, list } = data;
  return (
    <StyledReviewsBlock className="reviews">
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
      </Container>
      <ReviewsSlider items={list} />
    </StyledReviewsBlock>
  );
};

const StyledReviewsBlock = styled.div`
  background: #f2f6fc;
  padding: 40px 0 0;
  margin: 20px 0;
  @media (min-width: 768px) {
    margin: 70px 0;
  }
  h2 {
    margin: 0;
    color: #4c4c4c;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 28px;
    }
    span {
      font-weight: 400;
    }
  }
`;

export const query = graphql`
  fragment ReviewsBlock on WpPage_Components_Component_Reviews {
    fieldGroupName
    title
    list {
      name
      rating
      review
      photo {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 50, quality: 100, width: 50)
          }
        }
      }
    }
  }
`;
