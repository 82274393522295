import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import formBgSmall from "../../images/bg-blue.svg";

export const MainBackgroundImage = ({ children, heroImage }) => {
  const background = getImage(heroImage);

  return (
    <StyledBackgroundWrapper>
      <GatsbyImage className="main-background" image={background} alt="" />
      <img src={formBgSmall} className="main-background-small" alt="" />
      {children}
    </StyledBackgroundWrapper>
  );
};

const StyledBackgroundWrapper = styled.div`
  position: relative;
  .main-background {
    display: none;
  }

  @media (min-width: 992px) {
    .main-background {
      display: block;
      height: 400px;
    }
  }

  @media (min-width: 1280px) {
    .main-background {
      height: auto;
    }
  }

  .main-background-small {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 992px) {
    .main-background-small {
      display: none;
    }
  }
`;
