import React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import styled from "styled-components";

export const Info = ({ data }) => {
  const { text } = data;
  return (
    <StyledInfoBlock className="info">
      <Container>
        <div
          className="info_content-wrapper"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Container>
    </StyledInfoBlock>
  );
};

const StyledInfoBlock = styled.div`
  display: none;
  padding: 30px 0;
  color: #4c4c4c;
  font-size: 12px;
  text-align: center;
  @media (min-width: 768px) {
    display: block;
  }
  .info_content-wrapper {
    padding: 0 25px;
    ul {
      list-style: none;
    }
    li::before {
      content: "· ";
      font-weight: 700;
    }
    * {
      margin: 0;
      padding: 0;
    }
    * + * {
      margin: 10px 0 0;
    }
  }
`;

export const query = graphql`
  fragment InfoBlock on WpPage_Components_Component_Info {
    fieldGroupName
    text
  }
`;
