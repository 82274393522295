import React, { useContext } from "react";
import {
  Container,
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";
import { graphql } from "gatsby";
import styled from "styled-components";

import arrow from "../../images/arrow.svg";

export const Faq = ({ data }) => {
  const { title, info, questions } = data;

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <button
        type="button"
        className={isCurrentEventKey ? "active" : ""}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <StyledFaqBlock className="faq">
      <Container>
        {title && (
          <h2
            className="faq__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <Accordion>
          {questions &&
            questions.map((item, key) => (
              <Card key={key + 1}>
                <Card.Header className={"question_" + (key + 1)}>
                  <ContextAwareToggle eventKey={key + 1}>
                    {item.question}
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={key + 1}>
                  <Card.Body
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
        {info && (
          <div className="info" dangerouslySetInnerHTML={{ __html: info }} />
        )}
      </Container>
    </StyledFaqBlock>
  );
};

const StyledFaqBlock = styled.div`
  padding: 30px 0;
  h2 {
    color: rgba(55, 138, 255, 0.8);
    font-size: 20px;
    font-weight: 700;

    @media (min-width: 768px) {
      font-size: 28px;
    }
  }
  .faq__title + .accordion {
    margin: 20px 0 0;
    @media (min-width: 768px) {
      margin: 50px 0 0;
    }
  }
  .card {
    border: none;
    border-radius: 0;
  }
  .card-header {
    background: transparent;
    padding: 0;
    border: none;
    button {
      position: relative;
      background: transparent;
      border: none;
      padding: 0 0 0 19px;
      color: hsla(0, 0%, 30%, 0.8);
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      @media (min-width: 768px) {
        padding: 0 0 0 26px;
        color: #4c4c4c;
        font-size: 20px;
      }
      &:hover,
      &:focus {
        outline: transparent;
      }
      &::before {
        content: "";
        position: absolute;
        top: 6px;
        left: 2px;
        height: 11px;
        width: 6px;
        background: url(${arrow}) no-repeat center/contain;
        transform: rotate(0deg);
        transition: transform 0.15s ease;
        @media (min-width: 768px) {
          height: 14px;
          width: 8px;
        }
      }
      &:hover::before,
      &:focus::before {
        transform: translateX(3px);
      }
      &.active::before {
        transform: translateY(2px) rotate(90deg);
      }
    }
  }
  .card-body {
    padding: 4px 0 0 19px;
    color: #4c4c4c;
    font-size: 14px;
    @media (min-width: 768px) {
      padding: 15px 0 0 26px;
      font-size: 18px;
    }
    ul {
      list-style: none;
    }
    li::before {
      content: "· ";
      font-weight: 700;
    }
    * {
      margin: 0;
      padding: 0;
    }
    * + * {
      margin: 10px 0 0;
    }
    > * + * {
      margin: 20px 0 0;
      @media (min-width: 768px) {
        margin: 30px 0 0;
      }
    }
  }
  .card + .card {
    margin: 10px 0 0;
    @media (min-width: 768px) {
      margin: 30px 0 0;
    }
  }
  .info {
    padding: 30px 19px 0;
    border-top: 1px solid #d3d2d2;
    margin: 15px 0 0;
    color: hsla(0, 0%, 30%, 0.8);
    font-size: 12px;
    @media (min-width: 768px) {
      padding: 40px 0 0;
      margin: 50px 0 0;
      font-size: 14px;
    }
    ul {
      list-style: none;
    }
    li::before {
      content: "· ";
      font-weight: 700;
    }
    * {
      margin: 0;
      padding: 0;
    }
    * + * {
      margin: 10px 0 0;
    }
    > * + * {
      @media (min-width: 768px) {
        margin: 20px 0 0;
      }
    }
  }
`;

export const query = graphql`
  fragment FaqBlock on WpPage_Components_Component_Faq {
    fieldGroupName
    title
    questions {
      answer
      question
    }
    info
  }
`;
